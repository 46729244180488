import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

class ContactPage extends Component {
  componentDidMount() {
    //
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bagoly-Lak - Elérhetőség</title>
        </Helmet>
        <div className="main">
          <img className="main__background-full" src="assets/background-full.png" alt="Háttérkép" />
          <img
            className="main__background-minimal"
            src="assets/background-minimal.png"
            alt="Háttérkép"
          />
          <img className="main__logo" src="assets/bagoly-lak-logo.png" alt="Logo" />
          <div className="main__menu main__menu--desktop">
            <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
            <div className="main__menu__menus">
              <Link to="/">Bemutató</Link>
              <Link to="/matraszentimre">Mátraszentimre</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/hasznos-informaciok">Hasznos információk</Link>
              <Link to="/szabadidos-programok">Szabadidős programok</Link>
              <a
                href="https://nethotelbooking.net/hotels/bagoly-lak/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Foglalás
              </a>
              <Link to="/elerhetoseg">Elérhetőség</Link>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="main__menu main__menu--mobile">
                  <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
                  <div className="main__menu__menus">
                    <Link to="/">Bemutató</Link>
                    <Link to="/matraszentimre">Mátraszentimre</Link>
                    <Link to="/galeria">Galéria</Link>
                    <Link to="/hasznos-informaciok">Hasznos információk</Link>
                    <Link to="/szabadidos-programok">Szabadidős programok</Link>
                    <a
                      href="https://nethotelbooking.net/hotels/bagoly-lak/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Foglalás
                    </a>
                    <Link to="/elerhetoseg">Elérhetőség</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="contact">
                    <h1>Elérhetőség</h1>
                    <h2>Üzemeltető</h2>
                    <p>
                      GLOBAL-LINE Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság
                      <br />
                      Szálláshely címe:
                      <br />
                      3235 Mátraszentimre Jókai Mór utca 19
                      <br />
                      NTAK regisztrációs szám:
                      <br />
                      EG20001934
                    </p>
                    <h2>Ügyfélszolgálati elérhetőség</h2>
                    <p>
                      Global-Line Kft.
                      <br />
                      3000 Hatvan, Horváth M. út 4.
                      <br />
                      Adószám: 11173928-2-10
                      <br />
                      Tel.: +36 37 540240 <br />
                      +36 70 6128877 <br />
                      +36 20 6650863
                      <br />
                      <a className="link" href="mailto:info@bagoly-lak.hu">
                        <img className="email" src="assets/mail.svg" alt="Mail" />
                        Írj üzenetet itt!
                      </a>
                    </p>

                    <div className="bigFishContainer">
                      <Link
                        to="https://www.paymentgateway.hu/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="bigFish"
                          src="assets/pmgw-logo-narrow-white.png"
                          alt="PaymentGatewayLogo"
                          width={200}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main__separator" />
        </div>
      </>
    );
  }
}

export default ContactPage;
